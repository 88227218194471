<template>
  <div class="manage">
    <container>
      <div class="filter">
        <span class="active">全部职位</span>
        <span>开放中</span>
        <span>待开放</span>
        <span>审核失败</span>
        <span>已关闭</span>
      </div>
      <div class="search-wrap">
        <div>
          <el-button size="medium" type="primary" icon="el-icon-plus"
            >发布职位</el-button
          >
        </div>
        <div>
          <el-input
            size="medium"
            style="width: 220px; margin-right: 10px"
            placeholder="搜索职位"
          />
          <el-button size="medium">搜索</el-button>
        </div>
      </div>
    </container>

    <container>
      <div class="job-list">
        <div class="job-box" v-for="i in 10" :key="i">
          <div class="left">
            <div class="title">
              电路设计工程师

              <span class="level">
                <span>普</span>
              </span>
            </div>
            <div class="tags">
              <span>深圳</span>
              <span>1-3年</span>
              <span>本科</span>
              <span>10-15K</span>
            </div>
          </div>

          <div class="center">
            <div class="box">
              <div class="count">163</div>
              <div class="desc">看过我</div>
            </div>
            <div class="box">
              <div class="count">163</div>
              <div class="desc">沟通过</div>
            </div>
            <div class="box">
              <div class="count">163</div>
              <div class="desc">感兴趣</div>
            </div>
            <div class="tag box">
              <span></span>
              <span>开放中</span>
            </div>
          </div>

          <div class="right">
            <el-link type="primary" :underline="false">编辑</el-link>
            <el-link type="primary" style="margin: 0 10px" :underline="false"
              >关闭</el-link
            >
            <el-link type="primary" :underline="false">
              <i class="el-icon-more"></i>
            </el-link>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "./Container.vue";
export default {
  components: { Container },
};
</script>

<style lang="scss" scoped>
.manage {
  padding: 30px 0;

  .filter {
    margin-bottom: 15px;
    span {
      cursor: pointer;
      user-select: none;
      font-size: 14px;
      color: #666;
      display: inline-block;
      padding: 4px 8px;
      border-radius: 4px;

      &:hover,
      &:active,
      &.active {
        background: rgba($color: #1989fa, $alpha: 0.1);
        color: #1989fa;
        font-weight: bold;
      }
    }
  }

  .search-wrap {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }

  .job-list {
    .job-box {
      background: #fff;
      border: 1px solid #ededed;
      border-radius: 4px;
      padding: 20px 30px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      & + .job-box {
        margin-top: 15px;
      }

      .left {
        flex: 0 0 auto;
        width: 320px;

        .title {
          margin-bottom: 8px;

          .level {
            line-height: 1;
            color: green;
            font-size: 12px;
            padding: 1px;
            display: inline-block;
            border-radius: 2px;
            border: 1px solid green;
          }
        }

        .tags {
          font-size: 14px;
          color: #666;

          span + span {
            margin-left: 8px;
            padding-left: 8px;
            border-left: 2px solid #ededed;
          }
        }
      }

      .center {
        flex: 1 1 auto;

        display: flex;
        align-items: center;

        .box {
          margin: 0 20px;
          text-align: center;
          .desc {
            font-size: 14px;
            color: #666;
            margin-top: 4px;
          }
        }

        .tag {
          margin-left: 30px;
          font-size: 14px;

          span:nth-child(1) {
            margin-right: 8px;
            vertical-align: middle;
            display: inline-block;
            width: 6px;
            height: 6px;
            background: rgb(40, 241, 40);
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>